import { CircularProgress, LinearProgress, SxProps, Theme } from '@mui/material';

interface Props {
  variant?: 'circle';
  isLoading: boolean;
  sx?: SxProps<Theme>;
  size?: string;
}

/**
 * Returns a load state handling progress component
 * @param variant (optional) by default returns "bar"/LinearProgress
 * @param isLoading loading state, if false returns loading element
 * @param sx (optional) Materialui sx object to customize return element
 * @param size (optional) used in "circle" variant to change size
 * @returns loading element or null
 */
const ProgressComponent = ({ isLoading, variant, sx, size }: Props): JSX.Element => {
  function renderProgress() {
    if (variant === 'circle') {
      return <CircularProgress sx={sx} size={size} />;
    }

    return <LinearProgress sx={{ minHeight: '4px', ...sx }} />;
  }

  return <>{isLoading ? renderProgress() : null}</>;
};
export default ProgressComponent;
