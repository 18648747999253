import { Divider, Grid, Typography } from '@mui/material';
import { useContext, useMemo } from 'react';
import { LanguageContext } from '../../../../contexts/language-context';
import { LocationInfoFormData } from '../models';
import { printDate } from '../../../../util/calendarUtil';
import { EmptyingLocationContext } from '../provider/EmptyingLocationContextProvider';
import { ShowSummaryItem } from '../../../../util/showDataUtils';

interface Props {
  customerType: 'private' | 'company';
  locationInfoFormData: LocationInfoFormData;
}

const LocationSummary = ({ customerType, locationInfoFormData }: Props): JSX.Element => {
  const { getText } = useContext(LanguageContext);

  const { areaOptions, conurbationOptions, buildingUsageTypeOptions, buildingTypeOptions } =
    useContext(EmptyingLocationContext);

  const conurbationText = useMemo(() => {
    return conurbationOptions.find((item) => item.value === locationInfoFormData?.conurbation)?.text ?? '';
  }, [locationInfoFormData?.conurbation, conurbationOptions]);

  const areaText = useMemo(() => {
    return areaOptions.find((item) => item.value === locationInfoFormData?.area)?.text ?? '';
  }, [locationInfoFormData?.area, areaOptions]);

  const buildingUsageTypeText = useMemo(() => {
    return buildingUsageTypeOptions.find((item) => item.value === locationInfoFormData?.buildingUsageType)?.text ?? '';
  }, [locationInfoFormData?.buildingUsageType, buildingUsageTypeOptions]);

  const buildingTypeText = useMemo(() => {
    return buildingTypeOptions.find((item) => item.value === locationInfoFormData?.buildingType)?.text ?? '';
  }, [locationInfoFormData?.buildingType, buildingTypeOptions]);

  const locationContactName =
    locationInfoFormData.contactLastName || locationInfoFormData.contactFirstName
      ? `${locationInfoFormData.contactLastName}  ${locationInfoFormData.contactFirstName}`
      : null;

  return (
    <Grid container columns={2} direction='row' rowSpacing={2} sx={{ maxWidth: '540px' }}>
      <Grid item xs={2}>
        <Typography variant='h6'>{getText('add-emptying-location-basic-info')}</Typography>
      </Grid>

      {customerType === 'private' && (
        <Grid item xs={1}>
          <ShowSummaryItem
            labelKey='basic-info-name'
            data={`${locationInfoFormData.lastName}  ${locationInfoFormData.firstName}`}
          />
        </Grid>
      )}
      <Grid item xs={1}>
        <ShowSummaryItem
          labelKey='add-emptying-location-basic-info-street-address'
          data={locationInfoFormData.address}
        />
      </Grid>

      <Grid item xs={1}>
        <ShowSummaryItem labelKey='add-emptying-location-basic-info-post-code' data={locationInfoFormData.postCode} />
      </Grid>
      <Grid item xs={1}>
        <ShowSummaryItem labelKey='add-emptying-location-basic-info-city' data={locationInfoFormData.city} />
      </Grid>

      <Grid item xs={2}>
        <Divider />
      </Grid>

      <Grid item xs={2}>
        <Typography variant='h6'>{getText('add-emptying-location-contact')}</Typography>
      </Grid>

      <Grid item xs={1}>
        <ShowSummaryItem labelKey='basic-info-name' data={locationContactName} />
      </Grid>
      <Grid item xs={1}>
        <ShowSummaryItem labelKey='add-emptying-location-contact-phone' data={locationInfoFormData.contactPhone} />
      </Grid>

      <Grid item xs={1}>
        <ShowSummaryItem labelKey='add-emptying-location-contact-email' data={locationInfoFormData.contactEmail} />
      </Grid>

      <Grid item xs={2}>
        <Divider />
      </Grid>

      <Grid item xs={2}>
        <Typography variant='h6'>{getText('add-emptying-location-building-info')}</Typography>
      </Grid>

      <Grid item xs={1}>
        <ShowSummaryItem labelKey='add-emptying-location-building-info-area' data={areaText} />
      </Grid>
      <Grid item xs={1}>
        <ShowSummaryItem labelKey='add-emptying-location-building-info-conurbation' data={conurbationText} />
      </Grid>
      <Grid item xs={1}>
        <ShowSummaryItem
          labelKey='add-emptying-location-building-info-permanent-id'
          data={locationInfoFormData.permanentBuildingId}
        />
      </Grid>
      <Grid item xs={1}>
        <ShowSummaryItem labelKey='add-emptying-location-building-info-use' data={buildingUsageTypeText} />
      </Grid>
      <Grid item xs={1}>
        <ShowSummaryItem labelKey='add-emptying-location-building-info-type' data={buildingTypeText} />
      </Grid>
      <Grid item xs={1}>
        <ShowSummaryItem
          labelKey='add-emptying-location-building-info-apartment-count'
          data={locationInfoFormData.apartmentCount}
        />
      </Grid>
      <Grid item xs={1}>
        <ShowSummaryItem
          labelKey='add-emptying-location-building-info-population'
          data={locationInfoFormData.population}
        />
      </Grid>
      <Grid item xs={1}>
        <ShowSummaryItem
          labelKey='add-emptying-location-building-info-property-id'
          data={locationInfoFormData.propertyId}
        />
      </Grid>

      <Grid item xs={2}>
        <Divider />
      </Grid>

      <Grid item xs={2}>
        <Typography variant='h6'>{getText('add-emptying-location-contract-info')}</Typography>
      </Grid>

      <Grid item xs={1}>
        <ShowSummaryItem
          labelKey='add-emptying-location-contract-info-start-date'
          data={printDate(locationInfoFormData.startDate)}
        />
      </Grid>

      <Grid item xs={1}>
        <ShowSummaryItem
          labelKey='add-emptying-location-contract-info-reference-number'
          data={locationInfoFormData.referenceNumber}
        />
      </Grid>
    </Grid>
  );
};

export default LocationSummary;
